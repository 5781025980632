import { useEffect, useState } from 'react';
import { object } from 'prop-types';
import { Wrap } from '../../sections-wrap';
import { Select } from '../../common/custom-select';
import { LawyerCard } from '../../lawyer-card';
import { addNotDuplicatedArrayString } from '../../../helpers';
import * as styles from './index.module.scss';
import * as selectStyles from '../../common/custom-select/select.module.scss';
import { getLocalStorageItem, getQueryParams, setLocalStorageItem } from '../../../helpers/misc';

function Team({ data }) {
  const setStateSelectedFromLocalStorage = () => {
    let localStorageStateSelected = getLocalStorageItem('blogPostStateKey');
    if (localStorageStateSelected) {
      if (localStorageStateSelected === 'All states') localStorageStateSelected = 'Any';
      return localStorageStateSelected;
    }
    return '';
  };
  // states to manage STATE filter, just stateSelected is taking into account in this component
  const [stateSelected, setStateSelected] = useState(setStateSelectedFromLocalStorage());
  const [stateSelectOptions, setStateSelectOptions] = useState([]);
  // users could come from the home page in the tab attorney types with a specific kind of attorney
  const [practiceAreaSelected, setPracticeAreaSelected] = useState('');
  const [lawyers, setLawyers] = useState(data?.allWpTeamMember?.nodes);

  const allLawyers = data?.allWpTeamMember?.nodes;
  const practiceAreas = data?.allWpPage?.nodes;

  const lawyersPlaceholder = Array.from(Array(10).keys());
  lawyers?.sort((a, b) => {
    const aSplitted = a.title.split(' ');
    const bSplitted = b.title.split(' ');
    if (aSplitted[aSplitted.length - 1] === bSplitted[bSplitted.length - 1]) {
      return (
        (
          ((aSplitted[0] < bSplitted[0]) && !a.title.includes('Mike'))
           || b.title.includes('Mike')
            ? -1 : 1
        )
      );
    }
    return (
      (
        ((aSplitted[aSplitted.length - 1] < bSplitted[bSplitted.length - 1]) && !a.title.includes('Mike'))
         || b.title.includes('Mike')
          ? -1 : 1
      )
    );
  });
  practiceAreas?.sort((a, b) => (a.title?.localeCompare(b.title)));

  useEffect(() => {
    // To get the differents state that exists and load stateSelectOptions with it
    let auxSelectOptions = [];
    data.allWpLocation.nodes.forEach((location) => {
      auxSelectOptions = addNotDuplicatedArrayString(
        auxSelectOptions,
        location.locationSingleConfiguration.eaLocationState,
      );
    });
    setStateSelectOptions(auxSelectOptions);

    const urlParams = getQueryParams();
    if (urlParams) {
      setPracticeAreaSelected(urlParams.get('type'));
    }
  }, []);

  useEffect(() => {
    if ((!practiceAreaSelected || practiceAreaSelected === 'Any') && (!stateSelected || stateSelected === 'Any')) {
      setLawyers(allLawyers);
    } else {
      let retLawyers = allLawyers;
      if (practiceAreaSelected && practiceAreaSelected !== 'Any') {
        const practiceArea = practiceAreas?.filter(
          (item) => item.title === practiceAreaSelected,
        );
        let lawyersFiltered = practiceArea?.length
          ? practiceArea[0].practiceToTeamConnection.nodes : allLawyers;
        lawyersFiltered = lawyersFiltered?.map((item) => item.title);
        lawyersFiltered = allLawyers?.filter((lawyer) => lawyersFiltered?.includes(lawyer.title));
        lawyersFiltered?.sort((a, b) => (a.title?.localeCompare(b.title)));
        retLawyers = lawyersFiltered;
      }
      if (stateSelected && stateSelected !== 'Any') {
        retLawyers = retLawyers.filter(
          (lawyer) => lawyer.teamToLocationConnection.nodes.filter(
            (location) => location.locationSingleConfiguration.eaLocationState === stateSelected,
          ).length,
        );
      }
      setLawyers(retLawyers);
    }
  }, [practiceAreaSelected, stateSelected]);

  const updateStateSelected = (state) => {
    setStateSelected(state);
    setLocalStorageItem('blogPostStateKey', state);
  };

  return (
    <main>
      <div className={styles.pageContainer}>
        <Wrap>
          <main className={styles.dataContainer}>
            <header>
              <div className={styles.headerContainer}>
                <h1 className={styles.title}><span>Our Team</span></h1>
                <div className={styles.filtersContainer}>
                  <div className={styles.filter}>
                    <h3 className={styles.label}>Practice Area:</h3>
                    <div className={styles.selectContainer}>

                      <Select
                        label="Practice Area"
                        firstOption="Any"
                        value={practiceAreaSelected}
                        onChange={setPracticeAreaSelected}
                        onChangeFirstOption={() => setPracticeAreaSelected('')}
                        className={styles.selectCentered}
                      >
                        {practiceAreas?.map((item) => (
                          <button
                            onClick={() => setPracticeAreaSelected(item.title)}
                            type="button"
                            className={item.title === practiceAreaSelected
                              ? selectStyles.selected
                              : selectStyles.item}
                          >
                            <p className={selectStyles.text}>{item.title}</p>
                          </button>
                        ))}
                      </Select>
                    </div>
                  </div>
                  {stateSelectOptions && stateSelectOptions.length > 1 && (
                  <div className={styles.filter}>
                    <h3 className={styles.label}>State:</h3>
                    <div className={styles.selectContainer}>
                      <Select
                        label="State"
                        firstOption="Any"
                        value={stateSelected}
                        onChange={updateStateSelected}
                        onChangeFirstOption={() => updateStateSelected('')}
                        className={styles.selectCentered}
                      >
                        {stateSelectOptions?.map((item) => (
                          <button
                            onClick={() => updateStateSelected(item)}
                            type="button"
                            className={item === stateSelected
                              ? selectStyles.selected
                              : selectStyles.item}
                          >
                            <p className={selectStyles.text}>{item}</p>
                          </button>
                        ))}
                      </Select>
                    </div>
                  </div>
                  )}
                </div>
              </div>
            </header>
            <div className={styles.teamGrid}>
              {lawyers?.length > 0 || stateSelected || practiceAreaSelected
                ? lawyers.map((member) => (
                  <LawyerCard
                    key={member.title}
                    title={member.title}
                    uri={member.uri}
                    image={member?.featuredImage?.node?.gatsbyImage}
                    texts={member?.attorneyTeamSingleConfiguration}
                    className={styles.card}
                  />
                ))
                : lawyersPlaceholder.map(((item) => (
                  <div
                    key={item}
                    className={styles.card}
                  />
                )))}
            </div>
          </main>
        </Wrap>
      </div>
    </main>
  );
}

Team.propTypes = {
  data: object,

};

Team.defaultProps = {
  data: {},
};

export { Team };
