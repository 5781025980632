import { object } from 'prop-types';
import { graphql } from 'gatsby';
import { Team } from '../components/pages/team';

function AllTeamMemberTemplate({ data }) {
  return (
    <Team data={data} />
  );
}

export const query = graphql`
  {
    allWpPage(filter: {template: {templateName: {eq: "Practice Parent"}}}) {
      nodes {
        title
        practiceToTeamConnection {
          nodes {
            title
          }
        }
      }
    }
    allWpLocation {
      nodes {
        locationSingleConfiguration {
          eaLocationState
        }
      }
    }
    allWpTeamMember {
      nodes {
        title
        uri
        attorneyTeamSingleConfiguration {
          eaTeamQuickBio
          eaTeamSpecialty
        }
        teamToLocationConnection{
          nodes{
            locationSingleConfiguration{
              eaLocationState
            }
          }
        }
        featuredImage {
          node {
            gatsbyImage(width:300)
          }
        }
      }
    }
  }
`;

AllTeamMemberTemplate.propTypes = {
  data: object,
};

AllTeamMemberTemplate.defaultProps = {
  data: {},
};

export default AllTeamMemberTemplate;
export { Head } from '../components/seo/seo';
