import { string, object } from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { classNames } from '../../helpers';
import * as styles from './lawyer-card.module.scss';

function LawyerCard({
  title, uri, image, texts, className,
}) {
  const quickBio = texts?.eaTeamQuickBio?.length > 190 ? `${texts?.eaTeamQuickBio.slice(0, 190)}...`
    : texts?.eaTeamQuickBio;

  return (
    <Link to={uri}>
      <article className={classNames([styles.cardContainer, className])}>
        <div className={styles.wrap}>
          <GatsbyImage
            image={getImage(image)}
            alt={title}
            className={styles.image}
          />
          <div className={styles.content}>
            <div>
              <h4 className={styles.title}>
                <span to={uri}>{title}</span>
              </h4>
              <p>{texts?.eaTeamSpecialty}</p>
              <div className={styles.quickBio}>
                {quickBio}
                <span> View Profile </span>
              </div>
              {' '}
            </div>
          </div>
        </div>
      </article>
    </Link>
  );
}

LawyerCard.propTypes = {
  title: string,
  uri: string,
  image: object,
  texts: object,
  className: string,
};

LawyerCard.defaultProps = {
  title: '',
  uri: '',
  image: {},
  texts: {},
  className: '',
};

export { LawyerCard };
